import store from '../../store';
import { SET_JOB_STEP_FOUR } from '../store/wizards/mutations-types';
import { UI_ABILITIES } from '@/common/data/role-ui-abilities';
import { O365, MONITORING } from '@/common/data/constants';
// const USER_TOKEN_NAME = 'user-token';
const URL_TO_REDIRECT_AFTER_LOGIN = 'urlToRedirectAfterLogin';
// Layouts
import DashboardLayout from 'src/o365/pages/Layout/DashboardLayout.vue';
// Default
const Forbidden = () =>
  import(/* webpackChunkName: "O365Forbidden" */ 'src/common/pages/Forbidden.vue');
// User
const Profile = () => import(/* webpackChunkName: "Profile" */ 'src/common/pages/User/Profile.vue');
const UserSubscriptions = () =>
  import(/* webpackChunkName: "UserSubscriptions" */ 'src/common/pages/User/UserSubscriptions.vue');
const ChangePassword = () =>
  import(/* webpackChunkName: "ChangePassword" */ 'src/common/pages/User/ChangePassword.vue');
const ForceSecurityQuestions = () =>
  import(
    /* webpackChunkName: "ForceSecurityQuestions" */ 'src/common/pages/User/ForceSecurityQuestions.vue'
  );
// Users
const Users = () => import(/* webpackChunkName: "Users" */ 'src/o365/pages/Users/Users.vue');
const UserLogs = () =>
  import(/* webpackChunkName: "Users" */ 'src/common/pages/Users/UserLogs.vue');
//Downloads
const Support = () => import(/* webpackChunkName: "Support" */ 'src/monitoring/pages/Support.vue');
const LiveSupport = () =>
  import(/* webpackChunkName: "LiveSupport" */ 'src/monitoring/pages/LiveSupport.vue');
const Companies = () =>
  import(/* webpackChunkName: "Companies" */ 'src/o365/pages/Organizations/Companies/Companies');
const NewOrganizationOne = () =>
  import(
    /* webpackChunkName: "OrganizationsWizard" */ 'src/o365/pages/Organizations/Wizard/StepOne'
  );
const OrganizationThreeBasicAuth = () =>
  import(
    /* webpackChunkName: "OrganizationsWizard" */ 'src/o365/pages/Organizations/Wizard/StepThreeBasicAuth'
  );
const OrganizationThreeModernAuth = () =>
  import(
    /* webpackChunkName: "OrganizationsWizard" */ 'src/o365/pages/Organizations/Wizard/StepThreeModernAuth'
  );
const OrganizationThreeLegacyAuth = () =>
  import(
    /* webpackChunkName: "OrganizationsWizard" */ 'src/o365/pages/Organizations/Wizard/StepThreeLegacyAuth'
  );
const OrganizationFourBasicAuth = () =>
  import(
    /* webpackChunkName: "OrganizationsWizard" */ 'src/o365/pages/Organizations/Wizard/StepFourBasicAuth'
  );
const OrganizationFourLegacyAuth = () =>
  import(
    /* webpackChunkName: "OrganizationsWizard" */ 'src/o365/pages/Organizations/Wizard/StepFourLegacyAuth'
  );
const OrganizationFourModernAuthNew = () =>
  import(
    /* webpackChunkName: "OrganizationsWizard" */ 'src/o365/pages/Organizations/Wizard/StepFourModernAuthNew'
  );
const OrganizationFourModernAuthExisting = () =>
  import(
    /* webpackChunkName: "OrganizationsWizard" */ 'src/o365/pages/Organizations/Wizard/StepFourModernAuthExisting'
  );
const OrganizationFiveModernAuth = () =>
  import(
    /* webpackChunkName: "OrganizationsWizard" */ 'src/o365/pages/Organizations/Wizard/StepFiveModernAuth'
  );
const JobOne = () =>
  import(/* webpackChunkName: "JobsWizard" */ 'src/o365/pages/Jobs/Wizard/StepOne');
const JobTwo = () =>
  import(/* webpackChunkName: "JobsWizard" */ 'src/o365/pages/Jobs/Wizard/StepTwo');
const JobThree = () =>
  import(/* webpackChunkName: "JobsWizard" */ 'src/o365/pages/Jobs/Wizard/StepThree');
const JobFour = () =>
  import(/* webpackChunkName: "JobsWizard" */ 'src/o365/pages/Jobs/Wizard/StepFour');
const JobFive = () =>
  import(/* webpackChunkName: "JobsWizard" */ 'src/o365/pages/Jobs/Wizard/StepFive');
const Jobs = () => import(/* webpackChunkName: "Jobs" */ 'src/o365/pages/Jobs/Jobs');
const Repositories = () =>
  import(/* webpackChunkName: "Repositories" */ 'src/o365/pages/Repositories/Repositories');
const OrganizationRepo = () =>
  import(
    /* webpackChunkName: "OrganizationsWizard" */ 'src/o365/pages/Organizations/Wizard/RepositoryStep'
  );
const Licensing = () =>
  import(/* webpackChunkName: "O365Licensing" */ 'src/o365/pages/Licensing/Licensing');
const LicenseConsumption = () =>
  import(/* webpackChunkName: "O365LicenseConsumption" */ 'src/o365/pages/LicenseConsumption/LicenseConsumption');
const RoRoles = () =>
  import(
    /* webpackChunkName: "roRoles" */ 'src/o365/pages/RestoreOperatorRoles/RestoreOperatorRoles'
  );
const RoRoleOne = () =>
  import(
    /* webpackChunkName: "roRolesWizard" */ 'src/o365/pages/RestoreOperatorRoles/Wizard/StepOne'
  );
const RoRoleTwo = () =>
  import(
    /* webpackChunkName: "roRolesWizard" */ 'src/o365/pages/RestoreOperatorRoles/Wizard/StepTwo'
  );
const RoRoleThree = () =>
  import(
    /* webpackChunkName: "roRolesWizard" */ 'src/o365/pages/RestoreOperatorRoles/Wizard/StepThree'
  );
const RoRoleFour = () =>
  import(
    /* webpackChunkName: "roRolesWizard" */ 'src/o365/pages/RestoreOperatorRoles/Wizard/StepFour'
  );
const RoRoleFive = () =>
  import(
    /* webpackChunkName: "roRolesWizard" */ 'src/o365/pages/RestoreOperatorRoles/Wizard/StepFive'
  );
const Subscriptions = () =>
  import(/* webpackChunkName: "Subscriptions" */ 'src/o365/pages/Subscriptions/Subscriptions');
const Dashboard = () =>
  import(/* webpackChunkName: "Dashboard" */ 'src/o365/pages/Dashboard/Dashboard');
const RestoreOnedrive = () =>
  import(/* webpackChunkName: "RestoreOnedrive" */ 'src/o365/pages/RestoreOnedrive/RestoreOnedrive');
const RestoreOutlook = () =>
  import(/* webpackChunkName: "RestoreOutlook" */ 'src/o365/pages/RestoreOutlook/RestoreOutlook');
const RestoreSharepoint = () =>
  import(/* webpackChunkName: "RestoreSharepoint" */ 'src/o365/pages/RestoreSharepoint/RestoreSharepoint');
const RestoreTeams = () =>
  import(/* webpackChunkName: "RestoreTeams" */ 'src/o365/pages/RestoreTeams/RestoreTeams');
const RestorePortalLayout = () =>
  import(/* webpackChunkName: "RestorePortalLayout" */ 'src/o365/pages/Layout/RestorePortalLayout');
const ServiceLevel = () =>
  import(/* webpackChunkName: "ServiceLevel" */ 'src/o365/pages/ServiceLevel/ServiceLevel');

const isUserAuthenticated = to => {
  if (!store.getters.isAuthenticated) {
    if (to && to.name !== 'Session') {
      localStorage.setItem(URL_TO_REDIRECT_AFTER_LOGIN, JSON.stringify(to));
    }
    return false;
  }
  return true;
};

const validateUserSession = (to, from, next) => {
  if (!isUserAuthenticated(to)) {
    return next('/login');
  }
  next();
};

const validateUserSessionAndRol = (to, from, next, page) => {
  if (!isUserAuthenticated(to)) {
    return next('/login');
  }
  const user = store.getters.loggedInUser;
  console.log('validateUserSessionAndRol', user.acl, page);
  if (!UI_ABILITIES.check(user.acl, page)) {
    return next({ name: 'O365Forbidden' });
  }
  next();
};

const userHasAccessToO365App = () => {
  const user = store.getters.loggedInUser;
  return !!user.acl?.find(a => a.role === "o365_admin");
}

const checkAccessToO365AppValidateUserSessionAndRol = (to, from, next, endpoint) => {
  if (userHasAccessToO365App()) {
    if (endpoint) {
      validateUserSessionAndRol(to, from, next, endpoint);
    } else {
      next();
    }
  } else {
    next(`/${MONITORING}`);
  }
}

const routes = [
  // {
  //   path: '/',
  //   redirect: '/dashboard',
  //   name: 'Home',
  //   meta: { showFooter: true },
  //   beforeEnter: validateUserSession
  // },
  {
    path: `/${O365}`,
    component: DashboardLayout,
    redirect: `/${O365}/dashboard`,
    name: 'Root0365',
    beforeEnter: validateUserSession,
    children: [
      {
        path: `dashboard`,
        name: 'O365Dashboard',
        meta: { showFooter: true },
        components: { default: Dashboard },
        beforeEnter: (to, from, next) => checkAccessToO365AppValidateUserSessionAndRol(to, from, next, 'o365.dashboard'),
      },
      {
        path: `users`,
        name: 'O365Users',
        components: { default: Users },
        beforeEnter: (to, from, next) => checkAccessToO365AppValidateUserSessionAndRol(to, from, next, 'o365.users'),
      },
      {
        path: `users-logs`,
        name: 'O365UserLogs',
        components: { default: UserLogs },
        beforeEnter: (to, from, next) => checkAccessToO365AppValidateUserSessionAndRol(to, from, next, 'o365.userLogs'),
      },
      {
        path: `organizations`,
        name: 'O365Organizations',
        components: { default: Companies },
        beforeEnter: (to, from, next) => checkAccessToO365AppValidateUserSessionAndRol(to, from, next, 'o365.organizations'),
      },
      {
        path: `profile`,
        name: 'O365Profile',
        components: { default: Profile },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `subscriptions`,
        name: 'O365Subcriptions',
        components: { default: UserSubscriptions },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `change-password`,
        name: 'O365ChangePassword',
        components: { default: ChangePassword },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `reset-password`,
        name: 'O365ResetPassword',
        components: { default: ChangePassword },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `security-questions`,
        name: 'O365SecurityQuestions',
        components: { default: ForceSecurityQuestions },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `forbidden`,
        name: 'O365Forbidden',
        components: { default: Forbidden },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `support/request`,
        name: 'O365SupportRequest',
        components: { default: Support },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `support/live`,
        name: 'O365LiveSupport',
        components: { default: LiveSupport },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `organizations/new/1`,
        name: 'NewOrganizationOne',
        component: NewOrganizationOne,
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
        props: true
      },
      {
        path: `organizations/new/3/basic`,
        name: 'OrganizationThreeBasicAuth',
        components: { default: OrganizationThreeBasicAuth },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `organizations/new/3/modern`,
        name: 'OrganizationThreeModernAuth',
        components: { default: OrganizationThreeModernAuth },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `organizations/new/3/legacy`,
        name: 'OrganizationThreeLegacyAuth',
        components: { default: OrganizationThreeLegacyAuth },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `organizations/new/4/basic`,
        name: 'OrganizationFourBasicAuth',
        components: { default: OrganizationFourBasicAuth },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `organizations/new/4/legacy`,
        name: 'OrganizationFourLegacyAuth',
        components: { default: OrganizationFourLegacyAuth },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `organizations/new/4a/modern`,
        name: 'OrganizationFourModernAuthNew',
        components: { default: OrganizationFourModernAuthNew },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `organizations/new/4b/modern`,
        name: 'OrganizationFourModernAuthExisting',
        components: { default: OrganizationFourModernAuthExisting },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `organizations/new/5/modern`,
        name: 'OrganizationFiveModernAuth',
        components: { default: OrganizationFiveModernAuth },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `organizations/new/repo`,
        name: 'OrganizationRepo',
        components: { default: OrganizationRepo },
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `jobs/1`,
        name: 'JobOne',
        component: JobOne,
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
        props: true
      },
      {
        path: `jobs/2`,
        name: 'JobTwo',
        component: JobTwo,
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `jobs/3`,
        name: 'JobThree',
        component: JobThree,
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `jobs/4`,
        name: 'JobFour',
        component: JobFour,
        beforeEnter: async (to, from, next) => {
          checkAccessToO365AppValidateUserSessionAndRol(to, from, next);

          await store.dispatch('GET_PROXIES', store.getters.getJobWizStepOne.organizationId);
          await store.dispatch(
            'GET_O365_REPOSITORIES',
            store.getters.getJobWizStepOne.organizationId
          );
          const proxies = store.getters.getProxies;
          const repos = store.getters.getRepositories;
          if (proxies?.length === 1 && repos?.length === 1) {
            store.commit(SET_JOB_STEP_FOUR, { proxyId: proxies[0].id, repositoryId: repos[0].id });
            let newTo = '';
            if (from.name === 'JobThree') newTo = 'JobFive';
            else if (from.name === 'JobFive') newTo = 'JobThree';
            return next({ name: newTo });
          } else next();
        }
      },
      {
        path: `jobs/5`,
        name: 'JobFive',
        component: JobFive,
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `jobs`,
        name: 'O365Jobs',
        component: Jobs,
        beforeEnter: (to, from, next) => checkAccessToO365AppValidateUserSessionAndRol(to, from, next, 'o365.jobs'),
      },
      {
        path: `repositories`,
        name: 'O365Repositories',
        component: Repositories,
        beforeEnter: (to, from, next) => checkAccessToO365AppValidateUserSessionAndRol(to, from, next, 'o365.repositories'),
      },
      {
        path: `licensing`,
        name: 'O365Licensing',
        components: { default: Licensing },
        beforeEnter: (to, from, next) => checkAccessToO365AppValidateUserSessionAndRol(to, from, next, 'o365.licensing'),
      },
      {
        path: `license-consumption`,
        name: 'O365LicenseConsumption',
        components: { default: LicenseConsumption },
        beforeEnter: (to, from, next) => checkAccessToO365AppValidateUserSessionAndRol(to, from, next, 'o365.consumption'),
      },
      {
        path: `billing`,
        name: 'O365Subscriptions',
        components: { default: Subscriptions },
        beforeEnter: (to, from, next) => checkAccessToO365AppValidateUserSessionAndRol(to, from, next, 'o365.subscriptions'),
      },
      {
        path: `restore-operator-roles/1`,
        name: 'RoRoleOne',
        component: RoRoleOne,
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
        props: true
      },
      {
        path: `restore-operator-roles/2`,
        name: 'RoRoleTwo',
        component: RoRoleTwo,
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `restore-operator-roles/3`,
        name: 'RoRoleThree',
        component: RoRoleThree,
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `restore-operator-roles/4`,
        name: 'RoRoleFour',
        component: RoRoleFour,
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `restore-operator-roles/5`,
        name: 'RoRoleFive',
        component: RoRoleFive,
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `restore-operator-roles`,
        name: 'RoRoles',
        component: RoRoles,
        beforeEnter: checkAccessToO365AppValidateUserSessionAndRol,
      },
      {
        path: `service-level`,
        name: 'O365ServiceLevel',
        components: { default: ServiceLevel },
        beforeEnter: (to, from, next) => checkAccessToO365AppValidateUserSessionAndRol(to, from, next, 'o365.service-level'),
      }
    ]
  },
  {
    path: `/${O365}`,
    component: DashboardLayout,
    name: 'RestoreM365',
    beforeEnter: validateUserSession,
    children: [
      {
        path: `outlook`,
        name: 'M365RestoreOutlook',
        meta: { showFooter: true },
        components: { default: RestoreOutlook },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'o365.restoreOutlook')
      },
      {
        path: `onedrive`,
        name: 'M365RestoreOnedrive',
        meta: { showFooter: true },
        components: { default: RestoreOnedrive },
        beforeEnter: (to, from, next) => validateUserSessionAndRol(to, from, next, 'o365.restoreOnedrive')
      },
      {
        path: `sharepoint`,
        name: 'M365RestoreSharepoint',
        components: { default: RestoreSharepoint },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'o365.restoreSharepoint')
      },
      {
        path: `teams`,
        name: 'M365RestoreTeams',
        components: { default: RestoreTeams },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'o365.restoreTeams')
      },
    ]
  }
];

export default routes;
