var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper", class: { "nav-open": _vm.$sidebar.showSidebar } },
    [
      _c("notifications"),
      _vm._t("sidebar"),
      _c(
        "div",
        {
          staticClass: "main-panel",
          attrs: { id: "main-panel", data: _vm.sidebarBackground },
        },
        [
          _c("router-view", { attrs: { name: "header" } }),
          _c(
            "div",
            {
              class: { content: !_vm.$route.meta.hideContent },
              on: { click: _vm.toggleSidebar },
            },
            [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.getUserNotifications, function (notification) {
                  return _c(
                    "div",
                    { key: notification._id, staticClass: "col-12" },
                    [
                      notification.app === _vm.getSelectedApp
                        ? _c("Notification", {
                            staticClass: "mb-3",
                            staticStyle: { top: "unset", cursor: "pointer" },
                            attrs: {
                              type: notification.severity,
                              icon: "fa fa-bell",
                              ackButtonStyle:
                                "position: relative; margin-right: 40px;font-size: 12px;border-radius: 10px; top: -33px;",
                              showAck: "",
                              timeout: 0,
                              message: notification.message,
                              closeOnClick: false,
                            },
                            on: {
                              ack: function ($event) {
                                return _vm.handleNotificationAck(notification)
                              },
                              close: function ($event) {
                                return _vm.handleNotificationClose(
                                  notification._id
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "zoom-center-transition",
                { attrs: { duration: 200, mode: "out-in" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
          _vm.$route.meta.showFooter &&
          _vm.brandingGetter.features.footer.enabled
            ? _c("content-footer")
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }