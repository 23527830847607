import { render, staticRenderFns } from "./Wizard.vue?vue&type=template&id=240a1531&"
import script from "./Wizard.vue?vue&type=script&lang=js&"
export * from "./Wizard.vue?vue&type=script&lang=js&"
import style0 from "./Wizard.vue?vue&type=style&index=0&id=240a1531&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('240a1531')) {
      api.createRecord('240a1531', component.options)
    } else {
      api.reload('240a1531', component.options)
    }
    module.hot.accept("./Wizard.vue?vue&type=template&id=240a1531&", function () {
      api.rerender('240a1531', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/components/Wizard/Wizard.vue"
export default component.exports