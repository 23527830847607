var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "wrapper wrapper-full-page" },
      [
        _c("notifications"),
        _c(
          "div",
          {
            ref: "fullPage",
            staticClass: "full-page",
            class: [
              _vm.pageClass,
              _vm.isVMOGetter
                ? "vmo"
                : _vm.isHunterTechGetter
                ? "hunter-tech"
                : _vm.isDifferentDevGetter
                ? "different-dev"
                : null,
            ],
          },
          [
            _c(
              "div",
              { ref: "content", staticClass: "content" },
              [
                _c(
                  "zoom-center-transition",
                  {
                    attrs: {
                      duration: _vm.pageTransitionDuration,
                      mode: "out-in",
                    },
                  },
                  [_c("router-view")],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }